<template>
  <BackofficeBase>
    <div>
      <h2>Payme Orders</h2>
      <div v-if="payme_orders.length === 0" style="padding-bottom: 3rem;">
        There are no order
      </div>
      <div v-for="order in payme_orders" :key="order.id" class="item_list">
        <div>
          id: {{order.id}}<br />
          payme_sale_id: {{order.payme_sale_id}}<br />
          payme_transaction_id: {{order.payme_transaction_id}}<br />
          transaction_id: {{order.transaction_id}}<br />
          product_name: {{order.product_name}}<br />
          sale_price: {{order.sale_price}}<br />
          refunded_amount: {{order.refunded_amount}}<br />
          ts: {{order.ts}}<br />
        </div>
        <div class="item_action">
          <router-link :to="{path : '/backoffice/payme_order_delete', query : {'id' : order.id}}">Delete</router-link> |
          <router-link :to="{path : '/backoffice/payme_order_refund', query : {'id' : order.id}}">Refund</router-link>
        </div>
      </div>
    </div>
    <button v-on:click="payme_create_order">Create Order</button>
    <div>{{ status }}</div>
    <div>
      <iframe @load="ifc" class="payme_iframe" :src="iframe_src"></iframe>
    </div>
  </BackofficeBase>
</template>

<style>
.img_pre {
  max-width: 50em;
  margin-top: 1em;
}
</style>

<script>
import BackofficeBase from "./BackofficeBase"
import BackendModel from "../../models/BackendModel"

export default {
  name: 'BackofficePayme',
  components: {
    BackofficeBase
  },
  data() {
    return {
      status : "",
      iframe_src : "",
      payme_orders : []
    }
  },
  async mounted() {
    let backendModel = new BackendModel()
    let backoffice_payme_orders_res = await backendModel.backendRequest("/Api/service/backoffice/backoffice_get_payme_orders", {})
    this.payme_orders = backoffice_payme_orders_res.data.data
    this.loader_prop = false
  },
  methods : {
    async ifc(e) {
      console.log("-------------------------")
      console.log(e)
    },
    async payme_create_order() {
      this.status = 'Processing...'
      let backendModel = new BackendModel()
      let backoffice_payme_res = await backendModel.backendRequest("/Api/service/backoffice/backoffice_payme_create_order", {})
      if (backoffice_payme_res.data.payme_res_data.status_code != 0) {
        this.status = backoffice_payme_res.data.payme_res_data.status_error_details
      } else {
        // Success
        this.status = 'Ready to go'
        this.iframe_src = backoffice_payme_res.data.payme_res_data.sale_url
      }
    }
  }
}
</script>

<style>
.payme_iframe {
  border: none;
  width: 500px;
  height: 500px;
}
</style>
